import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./pages/Home/Home.jsx";
import Dashboard from "./pages/Controller/Dashboard.jsx";
import Login from "./pages/Auth/Login/Login.jsx";
import Signup from "./pages/Auth/Signup/Signup.jsx";
import HomePage from "./pages/Home/HomePage/HomePage.jsx";
import store from "./Store/index.js";
import HomeDashboard from "./pages/Controller/HomeDashboard/HomeDashboard.jsx";
import IsAuth from "./pages/Auth/isAuth/IsAuth.jsx";
import UserDetails from "./pages/Person/UserDetails.jsx";
import IsUser from "./pages/Auth/isAuth/IsUser.jsx";
import HomeDashboardUser from "./pages/Person/HomeDashboardUser/HomeDashboardUser.jsx";
import WalletUser from "./pages/Person/WalletUser/WalletUser.jsx";
import UserSubscriptions from "./pages/Person/UserSubscriptions/UserSubscriptions.jsx";
import USerRechargeBalance from "./pages/Person/USerRechargeBalance/USerRechargeBalance.jsx";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage.jsx";
import GetAllUser from "./pages/Controller/GetAllUser/GetAllUser.jsx";
import CreateCoupons from "./pages/Controller/CreateCoupons/CreateCoupons.jsx";
import AddPoints from "./pages/Controller/AddPoints/AddPoints.jsx";
import CreateUser from "./pages/Controller/CreateUser/CreateUser.jsx";
import CreateAdmin from "./pages/Controller/CreateUser/CreateAdmin/CreateAdmin.jsx";
import CreateTeacher from "./pages/Controller/CreateUser/CreateTeacher/CreateTeacher.jsx";
import CreateFiles from "./pages/Controller/CreateFiles/CreateFiles.jsx";
import CreateLecture from "./pages/Controller/CreateFiles/CreateLecture/CreateLecture.jsx";
import CreateClass from "./pages/Controller/CreateFiles/CreateClass/CreateClass.jsx";
import CreateSection from "./pages/Controller/CreateFiles/CreateSection/CreateSection.jsx";
import IsManager from "./pages/Auth/isAuth/IsManager.jsx";
import Accounts from "./pages/Controller/Accounts/Accounts.jsx";
import FreeVideoPage from "./pages/Home/FreeVideoPage/FreeVideoPage.jsx";
import CreateHeaderSlider from "./pages/Controller/Sliders/CreateHeaderSlider/CreateHeaderSlider.jsx";
import SliderPage from "./pages/Controller/Sliders/SliderPage.jsx";
import HonourSlider from "./pages/Controller/Sliders/HonourSlider/HonourSlider.jsx";
import GalleryTeacher from "./pages/Controller/Sliders/GalleryTeacher/GalleryTeacher.jsx";
import TeacherProfile from "./pages/Home/TeacherProfile/TeacherProfile.jsx";
import UserSafety from "./pages/Person/UserSafety/UserSafety.jsx";
import SectionPage from "./pages/Home/SectionPage/SectionPage.jsx";
import TeacherPage from "./pages/TeacherPage/TeacherPage.jsx";
import IsTeacher from "./pages/Auth/isAuth/IsTeacher.jsx";
// import VerifyCodeSignup from "./pages/Auth/VerifyCodeSignup/VerifyCodeSignup.jsx";
import LecutreWatchPage from "./pages/Home/LecutreWatchPage/LecutreWatchPage.jsx";
import HaveCourse from "./pages/Auth/HaveCourse/HaveCourse.jsx";
// import VideoUploadComponent from "./Test.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "free-videos",
        element: <FreeVideoPage />,
      },
      // {
      //   path: "test",
      //   element: <VideoUploadComponent />,
      // },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      // {
      //   path: "signup/verify",
      //   element: <VerifyCodeSignup />,
      // },
      {
        path: "me-teacher",
        element: (
          <IsTeacher>
            <TeacherPage />
          </IsTeacher>
        ),
      },
      {
        path: "teacher-profile/:id",
        element: <TeacherProfile />,
      },
      {
        path: "teacher-profile/:id/:section",
        element: <SectionPage />,
      },
      {
        path: "lecture-Watch/:LectureId/:courseId",
        element: (
          <HaveCourse>
            <LecutreWatchPage />
          </HaveCourse>
            ),
      },
      {
        path: "me",
        element: (
          <IsUser>
            <UserDetails />
          </IsUser>
        ),
        children: [
          {
            index: true,
            element: (
              <IsUser>
                <HomeDashboardUser />
              </IsUser>
            ),
          },
          {
            path: "wallet",
            element: (
              <IsUser>
                <WalletUser />
              </IsUser>
            ),
          },
          {
            path: "subscriptions",
            element: (
              <IsUser>
                <UserSubscriptions />
              </IsUser>
            ),
          },
          {
            path: "recharge-balance",
            element: (
              <IsUser>
                <USerRechargeBalance />
              </IsUser>
            ),
          },
          {
            path: "safety",
            element: (
              <IsUser>
                <UserSafety />
              </IsUser>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <IsAuth>
        <Dashboard />
      </IsAuth>
    ),
    children: [
      {
        index: true,
        element: (
          <IsAuth>
            <HomeDashboard />
          </IsAuth>
        ),
      },
      {
        path: "users",
        element: (
          <IsAuth>
            <GetAllUser />
          </IsAuth>
        ),
      },
      {
        path: "accounts",
        element: (
          <IsManager>
            <Accounts />
          </IsManager>
        ),
      },
      {
        path: "print-coupons",
        element: (
          <IsAuth>
            <CreateCoupons />
          </IsAuth>
        ),
      },
      {
        path: "add-points",
        element: (
          <IsAuth>
            <AddPoints />
          </IsAuth>
        ),
      },
      {
        path: "create-user",
        element: (
          <IsManager>
            <CreateUser />
          </IsManager>
        ),
        children: [
          {
            index: true,
            element: (
              <IsManager>
                <CreateAdmin />
              </IsManager>
            ),
          },
          {
            path: "teacher",
            element: (
              <IsManager>
                <CreateTeacher />
              </IsManager>
            ),
          },
        ],
      },
      {
        path: "create-files",
        element: (
          <IsAuth>
            <CreateFiles />
          </IsAuth>
        ),
        children: [
          {
            index: true,
            element: (
              <IsAuth>
                <CreateLecture />
              </IsAuth>
            ),
          },
          {
            path: "section",
            element: (
              <IsAuth>
                <CreateSection />
              </IsAuth>
            ),
          },
          {
            path: "class",
            element: (
              <IsAuth>
                <CreateClass />
              </IsAuth>
            ),
          },
        ],
      },
      {
        path: "sliders",
        element: (
          <IsAuth>
            <SliderPage />
          </IsAuth>
        ),
        children: [
          {
            index: true,
            element: (
              <IsAuth>
                <CreateHeaderSlider />
              </IsAuth>
            ),
          },
          {
            path: "honour",
            element: (
              <IsAuth>
                <HonourSlider />
              </IsAuth>
            ),
          },
          {
            path: "gallery-teacher",
            element: (
              <IsAuth>
                <GalleryTeacher />
              </IsAuth>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}
