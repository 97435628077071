import { MdEmail } from "react-icons/md";
import { useFormik } from "formik";
import { ResetPasswordValid } from "../../../../Util/VallationForms";
import { useSelector, useDispatch } from "react-redux";
import { sendEmail } from "../../../../Store/ForgetPassword";

export default function EmailResetPassword({ emailValue }) {
  const { loading } = useSelector((state) => state.forget);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email:emailValue || "",
    },
    validationSchema: ResetPasswordValid,
    onSubmit: async (values) => {
      try {
        dispatch(sendEmail(values));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  return (
    <form
      className="d-flex gap-4 flex-column p-2"
      onSubmit={formik.handleSubmit}
    >
      <h6 className="text-center mb-4">ارسل الكود الي الاميل الخاص بك</h6>
      <div className="input-group has-validation w-100">
        <span className="input-group-text rounded-0 rounded-end">
          <MdEmail className="fs-4" style={{ color: "var(--main-color)" }} />
        </span>
        <div className="form-floating is-invalid">
          <input
            type="text"
            className={`form-control rounded-0 rounded-start ${
              formik.touched.email && formik.errors.email && "is-invalid"
            }`}
            id="floatingInputGroup2"
            placeholder=""
            {...formik.getFieldProps("email")}
          />
          <label
            style={{ color: "var(--gray-color)" }}
            htmlFor="floatingInputGroup2"
          >
            ادخل الاميل
          </label>
        </div>
        {formik.touched.email && formik.errors.email && (
          <div className="invalid-feedback">{formik.errors.email}</div>
        )}
      </div>
      <button
        disabled={loading}
        type="button"
        onClick={formik.handleSubmit}
        className="btn btn-primary px-4 py-2 fs-5"
      >
        {loading ? "يتم الارسال" : "ارسل الكود"}
        {loading && (
          <>
            <span
              className="spinner-border spinner-border-sm mx-2"
              aria-hidden="true"
            ></span>
          </>
        )}
      </button>
    </form>
  );
}
