import {
  FaFacebookF,
  // FaInstagram,
  FaYoutube,
  // FaTiktok,
  FaWhatsapp,
} from "react-icons/fa6";
import { LiaTelegram } from "react-icons/lia";
import css from "./HomeFooter.module.css";

export default function HomeFooter() {
  return (
    <div className={`${css.footer}`}>
      <div className="py-3 text-center px-4 d-flex align-items-center justify-content-center flex-column">
        <h6>نبذة عنا</h6>
        <p dir="rtl">
          منصتنا التعليمية هي بيئة تعليمية شاملة تقدم دروسًا تفاعلية لطلاب
          الثانوية العامة والأزهرية بفضل نخبة من أفضل المدرسين، مما يضمن جودة
          تعليم عالية وتحفيز الطلاب لتحقيق النجاح والتميز الدراسي.
        </p>
      </div>
      <div
        className={`py-3 text-center px-4 d-flex align-items-center justify-content-center flex-column gap-4`}
      >
        <h6> وسائل التواصل الخاصة بنا</h6>
        <div className={css.icons}>
          {/* <div className={css.icon}>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </div> */}
          <div className={css.icon}>
            <a
              href="https://www.facebook.com/profile.php?id=100069956446265&mibextid=kFxxJD"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
          </div>
          <div className={css.icon}>
            <a
              href="https://youtube.com/channel/UCvONu2aNaXXUgYz8J-njcfw?si=vQZ7AOVvJl5gDHuM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </a>
          </div>
          {/* <div className={css.icon}>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
          </div> */}
          <div className={css.icon}>
            <a
              href="https://api.whatsapp.com/send?phone=201143949683&text=مرحبا اريد التواصل مع الدعم الفني "
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className={css.font} />
            </a>
          </div>
          <div className={css.icon}>
            <a
              href="https://t.me/+G-st3BfUwAthOTU0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LiaTelegram className={css.font} />
            </a>
          </div>
        </div>
      </div>
      <div
        className={`text-center py-3 flex-wrap d-flex align-items-center justify-content-center flex-column gap-3 ${css.developer}`}
      >
        <h6>تم انشاء هذا الموقع بواسطة </h6>
        <div>
          <a
            className="text-decoration-none"
            href="https://api.whatsapp.com/send?phone=201013265916&text=ابداع: السلام عليكم  "
            target="_blank"
            rel="noopener noreferrer"
          >
            Eng: Ahmed
          </a>
          <span> & </span>
          <a
            className="text-decoration-none"
            href="https://api.whatsapp.com/send?phone=201098648010&text=ابداع: السلام عليكم"
            target="_blank"
            rel="noopener noreferrer"
          >
            Eng: Eslam
          </a>
          <div>جميع حقوق النسخ محفوظة @2024</div>
        </div>
      </div>
    </div>
  );
}
