import React, { useEffect, useState } from "react";
import { FaRegFilePdf } from "react-icons/fa6";
import { IoVideocam } from "react-icons/io5";
import { IoBookmarksOutline } from "react-icons/io5";
import BtnBuy from "../Buttons/BtnBuy/BtnBuy";
import { useDispatch, useSelector } from "react-redux";
import videoAlreadyExists from "../../hooks/AlreadyExists";
import { getAllCourse, resetCourseData } from "../../Store/CourseSlice.js";
import WatchLectureBtn from "../Buttons/WatchLectureBtn/WatchLectureBtn.jsx";

export default function LectureComponents({ data, me }) {
  const [activeIndex, setActiveIndex] = useState(null);
  // const [CourseID, setCourseId] = useState(null);
  const meRedux = useSelector((state) => state.me);
  const coursesRedux = useSelector((state) => state.course);
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (meRedux.records.role === "user") {
      dispatch(getAllCourse());
    }
    if (coursesRedux.complete) {
      dispatch(resetCourseData());
      dispatch(getAllCourse());
    }
  }, [coursesRedux.complete, dispatch, meRedux.records.role]);

  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    return formattedDate;
  };

  const coursesExistsId = (id, courses) => {
    const exists = courses?.find((course) => course.lacture?._id === id);
    return { id: exists?._id, seen: exists?.seen };
  };

  return (
    <div
      style={{
        backgroundColor: "var(--bg-dark-bluer)",
        border: "1px solid var(--border-dark-bluer)",
        boxShadow: "var(--main-box-shadow)",
      }}
      className="container mb-5 p-3 rounded-2"
    >
      {data?.map((e, i) => (
        <div
          key={i}
          dir="ltr"
          className="accordion accordion-dark "
          id="accordionExample"
        >
          <div
            style={{
              backgroundColor: "var(--bg-dark-bluer)",
              border: "1px solid var(--border-dark-bluer)",
            }}
            className="accordion-item my-2 p-2"
          >
            <h2
              style={{
                backgroundColor: "var(--light2-color)",
              }}
              className="accordion-header  rounded-2 p-2  "
            >
              <div
                className={`accordion-button shadow-none  rounded-2 ${
                  activeIndex === i ? "" : "collapsed"
                }`}
                style={{
                  backgroundColor: "var(--light2-color)",
                }}
                type="button"
                onClick={() => handleToggle(i)}
                aria-expanded={activeIndex === i}
                aria-controls={`collapse${i}`}
              >
                <div
                  style={{ transition: "0.3s" }}
                  className="w-100 px-2 d-flex  align-items-center justify-content-between text-primary"
                >
                  <span className="fw-bold">{`${i + 1} - ${
                    me ? e.lacture.lecture : e.lecture
                  }`}</span>
                  <IoBookmarksOutline className="fs-3 me-2 " />
                </div>
              </div>
            </h2>
            <div
              id={`collapse${i}`}
              className={`accordion-collapse collapse ${
                activeIndex === i ? "show" : ""
              }`}
              data-bs-parent="#accordionExample"
            >
              <div
                style={{
                  backgroundColor: "var(--light2-color)",
                  border: "1px solid var(--border-dark-bluer)",
                }}
                className="accordion-body my-2 rounded-2"
              >
                {!videoAlreadyExists(
                  me ? e.lacture._id : e._id,
                  coursesRedux?.records?.data?.couresItems
                ) && (
                  <div className="text-center mb-4 mt-2">
                    <h5
                      style={{ textShadow: "var(--dark-color) 0px 0px 15px" }}
                    >
                      سعر المحاضره{" "}
                      <span className="text-primary">
                        {" "}
                        {me ? e.lacture.price : e.price}ج.م
                      </span>
                    </h5>
                  </div>
                )}
                <h6
                  className="my-3 text-center"
                  style={{
                    fontSize: "13px",
                    color: "var(--gray-color)",
                  }}
                >
                  <span className="text-primary">وصف المحاضره:</span>{" "}
                  {me ? e.lacture.description : e.description}
                </h6>
                {meRedux.records.role === "user" &&
                  videoAlreadyExists(
                    me ? e.lacture._id : e._id,
                    coursesRedux?.records?.data?.couresItems
                  ) && (
                    <div className="text-end my-2">
                      <span
                        style={{ fontSize: "12px" }}
                        className=" px-2 text-bg-danger rounded-5 "
                      >
                        متبقي{" "}
                        {
                          coursesExistsId(
                            me ? e?.lacture?._id : e?._id,
                            coursesRedux?.records?.data?.couresItems
                          )?.seen
                        }{" "}
                        مشاهدات
                      </span>
                    </div>
                  )}
                {(me ? e.lacture.pdf : e.pdf) && (
                  <div className=" p-2 d-flex align-items-center justify-content-between rounded border border-warning">
                    <span className="text-warning"> يوجد PDF</span>
                    <span>
                      <FaRegFilePdf className="fs-5 text-warning" />
                    </span>
                  </div>
                )}
                {(me ? e.lacture.video : e.video) && (
                  <div className=" p-2 mt-2 d-flex align-items-center justify-content-between rounded border border-danger ">
                    <span className="text-danger">يوجد فيديو</span>
                    <span>
                      <IoVideocam className="fs-5 text-danger" />
                    </span>
                  </div>
                )}
                {/* {e.quiz && (
                  <div className=" p-2 d-flex mt-2 align-items-center justify-content-between rounded border border-success">
                    <span> {`${e?.quiz}`}</span>
                    <span>
                      <MdQuiz className="fs-5" />
                    </span>
                  </div>
                )} */}
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="text-end">
                    <span
                      style={{ fontSize: "12px" }}
                      className=" px-2 text-bg-primary rounded-5"
                    >
                      تاريخ انشاء :{" "}
                      {handleExpiryTime(me ? e.lacture.createdAt : e.createdAt)}
                    </span>
                  </div>
                  {videoAlreadyExists(
                    me ? e.lacture._id : e._id,
                    coursesRedux?.records?.data?.couresItems
                  ) ||
                  meRedux?.records?.role === "manager" ||
                  meRedux?.records?.role === "teacher" ? (
                    <>
                      <WatchLectureBtn
                        LectureId={me ? e.lacture._id : e._id}
                        courseId={
                          coursesExistsId(
                            me ? e?.lacture?._id : e?._id,
                            coursesRedux?.records?.data?.couresItems
                          )?.id
                        }
                      />
                    </>
                  ) : (
                    meRedux?.records?.role !== "admin" &&
                    meRedux?.records?.role !== "manager" &&
                    meRedux?.records?.role !== "teacher" && (
                      <BtnBuy data={me ? e.lacture : e} type="lacture" />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
