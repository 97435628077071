import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourse } from "../../../Store/CourseSlice.js";
import { getMe } from "../../../Store/getMeSlice.js";
import LoadingModel from "../../../Model/Loading/Loading.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { setError } from "../../../Store/ErrorSlice.js";

export default function HaveCourse({ children }) {
  const [open, setOpen] = useState(false);
  const { records: courseRecords, loading: courseLoading } = useSelector(
    (state) => state.course
  );
  const { records: meRecords, loading: meLoading } = useSelector(
    (state) => state.me
  );
  const { LectureId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGetMe = async () => {
      await dispatch(getMe());
    };
    if (!meRecords.complete) {
      fetchGetMe();
    }

    const fetchCourses = async () => {
      await dispatch(getAllCourse());
    };
    if (meRecords.role === "user") {
      fetchCourses();
    }
  }, [dispatch, meRecords.complete, meRecords.role]);

  useEffect(() => {
    const checkVideoExists = () => {
      if (meRecords.role === "manager" || meRecords.role === "teacher") {
        setOpen(true);
      } else if (courseRecords?.data?.couresItems?.length > 0) {
        const video = courseRecords.data.couresItems.find(
          (course) => course.lacture._id === LectureId
        );
        const shouldOpen = video && meRecords.ip === video.ip;
        if (!shouldOpen) {
          dispatch(
            setError({
              type: "error",
              message: "لم يتم شراء هذة المحاضره من هذا الجهاز ",
              location: "مشاهدة المحاضره",
            })
          );
          navigate("/");
        } else {
          setOpen(true);
        }
      }
    };

    // تأكد من أن البيانات جاهزة قبل التحقق
    if (!meLoading && !courseLoading) {
      checkVideoExists();
    }
  }, [
    courseRecords?.data,
    meRecords.ip,
    meRecords.role,
    LectureId,
    dispatch,
    meLoading,
    courseLoading,
  ]);

  // if (meLoading || courseLoading) {
  //   return <LoadingModel />;
  // }

  if (open) {
    return children;
  }

  return <LoadingModel />;
}
